export function SET_ACCOUNT(state, account) {
  state.account = account
}

export function SET_ACCOUNTS(state, accounts) {
  state.accounts = accounts
}

export function SET_AUTHORITY_ACCOUNTS(state, authorityAccounts) {
  state.authorityAccounts = authorityAccounts
}

export function SET_PRODUCTS(state, products) {
  state.products = products
}
